import type { FC } from 'react';
import { memo } from 'react';
import cn from 'classnames';

import type { ScoreboardTimer } from 'components/betting/Match/ScoreBoard/types';
import PackIcon, { IconsPack } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import { withClientRender } from 'hocs';
import { useTimer } from 'hooks';
import type { MatchBase, SportEventState } from 'types/gql.bet';
import { BetTypeTabs } from '../../../constants';
import { Score } from '../types';
import useSportEventInfo from './useSportEventInfo';

const eclipsisClass = cn('truncate');

interface Props {
    sportEvent: MatchBase;
    sportEventState: SportEventState;
    score: Score | null;
    outcomesCount: number;
    betTypeTabs: BetTypeTabs;
    isRefundStatusLoss: boolean;
}

const SportEventInfo: FC<Props> = ({
    sportEvent,
    sportEventState,
    score,
    outcomesCount,
    betTypeTabs,
    isRefundStatusLoss,
}) => {
    const isSettle = betTypeTabs === BetTypeTabs.Settled;

    const { isLive, isNearest, linkEvent, titleBlock, dateBlock, timer } =
        useSportEventInfo({
            sportEvent,
            sportEventState,
            outcomesCount,
            isSettle,
        });

    const textColor = isRefundStatusLoss ? 'text-grey-700' : 'text-grey-500';

    return (
        <div
            className={cn(
                'flex items-center border-t-default border-surface-dark px-4 typo-sm-1',
                isLive ? 'py-2' : 'py-3',
                { 'justify-between': !isNearest }
            )}
        >
            <div
                className="flex items-center overflow-hidden"
                title={sportEvent.fixture.title}
            >
                {isLive && (
                    <PackIcon
                        id="live"
                        pack={IconsPack.SpriteIcons}
                        className={cn(
                            'mr-2 flex-none',
                            isRefundStatusLoss
                                ? 'fill-grey-900 text-grey-700'
                                : 'fill-[#550000] text-red-300'
                        )}
                    />
                )}
                <SmartLink
                    href={linkEvent}
                    className={cn('overflow-hidden', {
                        'text-grey-700': isRefundStatusLoss,
                    })}
                >
                    {typeof titleBlock === 'string' ? (
                        <div
                            className={cn('flex items-center', eclipsisClass, {
                                'text-grey-500': isNearest,
                            })}
                        >
                            {titleBlock}
                            {isNearest && (
                                <div
                                    className={cn(
                                        'mx-2 w-2 border-default border-b-[0px]',
                                        isRefundStatusLoss
                                            ? 'border-grey-500'
                                            : 'border-grey-500'
                                    )}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={eclipsisClass}>
                            {titleBlock[0]}
                            <span className={textColor}>vs</span>
                            {titleBlock[1]}
                        </div>
                    )}
                </SmartLink>
            </div>
            {!isLive && (
                <span
                    className={cn('flex-none', textColor, {
                        'ml-2': !isNearest,
                    })}
                >
                    {dateBlock}
                </span>
            )}
            {score && (isLive || isSettle) && (
                <div
                    title={`${score.title} ${score.value}`}
                    className={cn('ml-2 flex flex-none', textColor)}
                >
                    {`(${score.value})`}
                    {!isSettle && (
                        <div className="ml-1">
                            <Timer timer={timer} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const Timer: FC<{ timer: ScoreboardTimer }> = withClientRender(({ timer }) => {
    const { timeToShow } = useTimer(timer);

    return <span className="ml-1">{timeToShow}</span>;
});

export default memo(SportEventInfo);
