import type { FC } from 'react';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { BetConditionDetailsType, BetType, OddStatus } from 'types/gql.bet';
import { RefundStatus } from '../constants';

interface Props {
    condition: BetConditionDetailsType | RefundStatus | BetType | OddStatus;
}

const ConditionalIcon: FC<Props> = ({ condition }) => {
    switch (condition) {
        case RefundStatus.Win:
        case OddStatus.Win:
        case OddStatus.HalfWin:
            return (
                <PackIcon
                    id="win"
                    pack={IconsPack.BetHistoryIcons}
                    className="fill-green-700"
                />
            );
        case BetConditionDetailsType.Express:
            return (
                <PackIcon
                    id="combo"
                    pack={IconsPack.BetHistoryIcons}
                    className="fill-grey-500"
                />
            );
        case BetConditionDetailsType.System:
            return (
                <PackIcon
                    id="system"
                    pack={IconsPack.BetHistoryIcons}
                    className="fill-grey-500"
                />
            );
        case RefundStatus.CashOuted:
        case RefundStatus.RollBack:
        case OddStatus.Refunded:
            return (
                <PackIcon
                    id="return"
                    pack={IconsPack.BetHistoryIcons}
                    className="fill-green-700"
                />
            );
        case RefundStatus.Loss:
        case OddStatus.Loss:
        case OddStatus.HalfLoss:
            return (
                <PackIcon
                    id="loss"
                    pack={IconsPack.BetHistoryIcons}
                    className="fill-grey-700"
                />
            );

        default:
            return null;
    }
};

export default ConditionalIcon;
