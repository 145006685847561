import type { FC } from 'react';
import { memo } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import cn from 'classnames';

import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { Bet, BetType } from 'types/gql.bet';
import { RefundStatus } from '../../constants';
import Footer from './Footer';
import Header from './Header';
import OutcomesOdd from './OutcomesOdd';

interface Props {
    toogle: VoidFunction;
    odds: Bet['odds'];
    betId: string;
    betType: BetType;
    refundStatus: RefundStatus;
}

const OutcomesPortal: FC<Props> = ({
    toogle,
    betId,
    odds,
    refundStatus,
    betType,
}) => {
    const { between } = useResponsive();
    const isRenderSidebarPopup = between('sm', 'lg');

    return (
        <div className="absolute inset-x-0 bottom-0 top-12 flex flex-col !overflow-hidden bg-surface-dark">
            <Header onClickHandler={toogle} betType={betType} betId={betId} />
            <Scrollbars
                hideTracksWhenNotNeeded={true}
                universal={true}
                renderView={(props) => (
                    <div
                        {...props}
                        className={cn({ 'pb-12': isRenderSidebarPopup })}
                    />
                )}
            >
                <div className="flex flex-col gap-2 px-2 pb-2">
                    {odds.map((odd, i) => (
                        <OutcomesOdd
                            key={i}
                            odds={[odd]}
                            refundStatus={refundStatus}
                        />
                    ))}
                    <Footer />
                </div>
            </Scrollbars>
        </div>
    );
};

export default memo(OutcomesPortal);
