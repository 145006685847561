import { BetStatus } from 'types/api.bet';
import type { BetStatusesByBetTypeTabs } from './types';

export const BET_LIST_LIMIT = 10 as const;

export enum BetTypeTabs {
    Settled = 'settled',
    Unsettled = 'unsettled',
}

export enum RefundStatus {
    Win = 'WIN',
    Loss = 'LOSS',
    RollBack = 'ROLL-BACK',
    Pending = 'PENDING',
    CashOuted = 'CASHOUTED',
}

export const BET_UNSETTLED_STATUSES = [
    BetStatus.Placed,
    BetStatus.ArbitrageAccepted,
    BetStatus.Accepted,
    BetStatus.Unsettled,
] as const;

export const BET_SETTLED_STATUSES = [BetStatus.Settled, BetStatus.RolledBack];

export const BET_STATUSES_BY_BET_TYPE: BetStatusesByBetTypeTabs = {
    [BetTypeTabs.Settled]: BET_SETTLED_STATUSES,
    [BetTypeTabs.Unsettled]: BET_UNSETTLED_STATUSES,
} as const;
