import { Sports } from 'components/betting/Categorizer/constants';
import { SpecifierName } from './constants';

export function getMainSpecifiersBySportId(sportId: string): SpecifierName[] {
    switch (sportId) {
        case Sports.ICE_HOCKEY:
        case Sports.ESPORTS_ICE_HOCKEY: {
            return [SpecifierName.periodnr, SpecifierName.overtimenr];
        }

        case Sports.FOOTBALL:
        case Sports.ESPORTS_FIFA:
        case Sports.HANDBALL:
        case Sports.FUTSAL: {
            return [SpecifierName.halfnr, SpecifierName.overtimenr];
        }

        case Sports.ESPORTS_BASEBALL:
        case Sports.BASEBALL: {
            return [SpecifierName.inningnr];
        }

        case Sports.AMERICAN_FOOTBALL: {
            return [SpecifierName.halfnr, SpecifierName.quarternr];
        }

        case Sports.BASKETBALL:
        case Sports.ESPORTS_BASKETBALL: {
            return [
                SpecifierName.quarternr,
                SpecifierName.halfnr,
                SpecifierName.overtimenr,
            ];
        }

        case Sports.TENNIS:
        case Sports.TABLE_TENNIS:
        case Sports.ESPORTS_TENNIS:
        case Sports.VOLLEYBALL:
        case Sports.ESPORTS_VOLLEYBALL:
        case Sports.BEACH_VOLLEYBALL:
        case Sports.BADMINTON: {
            return [SpecifierName.setnr];
        }

        case Sports.ESPORTS_ARTIFACT:
        case Sports.ESPORTS_FORTNITE:
        case Sports.ESPORTS_CALL_OF_DUTY_WARZONE: {
            return [SpecifierName.gamenr];
        }

        case Sports.ESPORTS_DOTA_2:
        case Sports.ESPORTS_COUNTER_STRIKE:
        case Sports.ESPORTS_RAINBOW_SIX:
        case Sports.ESPORTS_CALL_OF_DUTY:
        case Sports.ESPORTS_STARCRAFT:
        case Sports.ESPORTS_STARCRAFT_1:
        case Sports.ESPORTS_WORLD_OF_TANKS:
        case Sports.ESPORTS_HEARTHSTONE:
        case Sports.ESPORTS_HEROES_OF_THE_STORM:
        case Sports.ESPORTS_LEAGUE_OF_LEGENDS:
        case Sports.ESPORTS_OVERWATCH:
        case Sports.ESPORTS_VAINGLORY:
        case Sports.ESPORTS_WARCRAFT_3:
        case Sports.ESPORTS_ROCKET_LEAGUE:
        case Sports.ESPORTS_SMITE:
        case Sports.ESPORTS_SOCCER_MYTHICAL:
        case Sports.ESPORTS_HALO:
        case Sports.ESPORTS_CROSSFIRE:
        case Sports.ESPORTS_STREET_FIGHTER_5:
        case Sports.ESPORTS_KING_OF_GLORY:
        case Sports.ESPORTS_VALORANT:
        case Sports.ESPORTS_BATTLEGROUNDS:
        default: {
            return [SpecifierName.mapnr];
        }
    }
}
