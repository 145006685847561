import { fillLink } from 'cl-fill-link';
import { useTranslations } from 'next-intl';

import { AppLink, SportEventMetaKey } from 'app-constants';
import { Sports } from 'components/betting/Categorizer/constants';
import type { ScoreboardTimer } from 'components/betting/Match/ScoreBoard/types';
import { useDateText } from 'hooks';
import type { MatchBase } from 'types/gql.bet';
import { SportEventState, SportTag } from 'types/gql.bet';
import { getMetaValueByName } from 'utils';
import { ESPORT_EVENT_LINK, SPORT_EVENT_LINK } from '../constants';

const timerDecrementSportList = [
    Sports.ICE_HOCKEY,
    Sports.BASKETBALL,
    Sports.ESPORTS_COUNTER_STRIKE,
    Sports.ESPORTS_VOLTA_FOOTBALL,
    Sports.ESPORTS_ROCKET_LEAGUE,
];

interface Input {
    sportEvent: MatchBase;
    sportEventState: SportEventState;
    outcomesCount: number;
    isSettle: boolean;
}

interface Output {
    isLive: boolean;
    isNearest: boolean;
    linkEvent: string;
    titleBlock: string[] | string;
    dateBlock: string;
    timer: ScoreboardTimer;
}

const useSportEventInfo = ({
    sportEvent,
    sportEventState,
    outcomesCount,
    isSettle,
}: Input): Output => {
    const { meta, slug } = sportEvent;
    const { startTime, title, sportId, type, sport } = sportEvent.fixture;
    const tBettingGlobal = useTranslations('betting-global.global');
    const tBettingBetshistory = useTranslations(
        'betting-betshistory.betsHistory.Odd'
    );

    const isLive = sportEventState === SportEventState.Live;
    const isNearest = !isLive && outcomesCount > 1;

    const linkBySportType =
        sport.tags[0] === SportTag.Esport
            ? AppLink.ESPORTS_RESULT_SLUG
            : AppLink.SPORTS_RESULT_SLUG;

    const sportEventLinkBySportType =
        sport.tags[0] === SportTag.Esport
            ? ESPORT_EVENT_LINK[type]
            : SPORT_EVENT_LINK[type];

    const path = isSettle ? linkBySportType : sportEventLinkBySportType;
    const linkEvent = fillLink(path, {
        slug,
    });

    const timerTime = Number(getMetaValueByName(meta, SportEventMetaKey.Time));
    const isTimerRunning = !!getMetaValueByName(
        meta,
        SportEventMetaKey.TimerRunning
    );

    const timer = {
        isTimerRunning,
        time: timerTime,
        isDecrement: timerDecrementSportList.includes(sportId as Sports),
    };

    const titleSplit = title.split('vs');
    const titleFromSplit = titleSplit.length > 1 ? titleSplit : title;
    const titleBlock =
        isLive || outcomesCount === 1
            ? titleFromSplit
            : tBettingBetshistory('nearestMatch');

    const date = new Date(startTime);
    const dateBlock = useDateText({
        startTime: date,
        translate: tBettingGlobal,
        isTime: true,
    });

    return {
        isLive,
        isNearest,
        linkEvent,
        titleBlock,
        dateBlock,
        timer,
    };
};

export default useSportEventInfo;
