import { useReactiveVar } from '@apollo/client';

import { gamblerSettingsVar } from 'components/betting/GamblerSettings/gamblerState';
import {
    BetOdd,
    MarketBase,
    MatchBase,
    OddStatus,
    SportEventState,
} from 'types/gql.bet';
import { formatDecimalOdd } from 'utils';
import { betTypeVar } from '../../betHistoryState';
import { BetTypeTabs } from '../../constants';
import { getScore } from '../../utils';
import { Score } from './types';

interface Input {
    odds: Array<BetOdd>;
}

interface Output {
    sportEvent: MatchBase;
    value: string | number;
    sportEventState: SportEventState;
    outcomesCount: number;
    score: Score | null;
    betTypeTabs: BetTypeTabs;
    market: MarketBase;
    name: string;
    status: OddStatus;
}

const useOdd = ({ odds }: Input): Output => {
    const betTypeTabs = useReactiveVar(betTypeVar);

    const { oddFormat } = useReactiveVar(gamblerSettingsVar);

    const oddsSort = [...odds].sort(
        (a, b) =>
            Date.parse(a.sportEvent.fixture.startTime) -
            Date.parse(b.sportEvent.fixture.startTime)
    );

    const outcomesCount = odds.length;

    const {
        sportEvent,
        sportEventState,
        market,
        odd: { name, status },
    } = oddsSort[0];

    const scoreFiltered = sportEvent.fixture.competitors.filter(
        ({ homeAway, score }) => ({
            homeAway,
            score,
        })
    );

    return {
        betTypeTabs,
        outcomesCount,
        score: getScore({ sportEvent, market, scores: scoreFiltered }),
        sportEvent,
        value: formatDecimalOdd(odds[0].odd.value, oddFormat),
        sportEventState,
        market,
        name,
        status,
    };
};

export default useOdd;
