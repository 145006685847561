import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react';

import { BetInsurance } from 'types/api.bet';
import { Bet, BetOdd, BetType } from 'types/gql.bet';
import type { Refund, Totals } from '../types';
import { getRefundInfo } from '../utils';
import useBetItem from './useBetItem';

interface IBetItemContext {
    betId: string;
    odds: Array<BetOdd>;
    betType: BetType;
    refundInfo: Refund;
    totalValue: string | number;
    freebetId: string;
    systemSizes: Array<number>;
    totals: Totals;
    createdAt: string;
    insurance?: BetInsurance | null;
}

export const BetItemContext = createContext<IBetItemContext>({
    betId: '',
    odds: [],
    betType: '' as BetType,
    refundInfo: {} as Refund,
    totalValue: '',
    systemSizes: [],
    freebetId: '',
    totals: {} as Totals,
    createdAt: '',
    insurance: {} as BetInsurance,
});

const BetItemProvider: FC<PropsWithChildren<{ bet: Bet }>> = ({
    children,
    bet,
}) => {
    const {
        stake,
        type,
        freebetId,
        systemSizes,
        odds,
        id,
        createdAt,
        insurance,
    } = bet;

    const { totals, totalValue } = useBetItem({
        bet: { type, stake, freebetId, systemSizes, odds },
    });

    const refundInfo = getRefundInfo({
        bet,
        totalWin: totals.totalWin,
    });

    const value = useMemo(
        () => ({
            betId: id,
            odds,
            betType: type,
            refundInfo,
            systemSizes,
            freebetId,
            totals,
            totalValue,
            createdAt,
            insurance,
        }),
        [
            id,
            odds,
            type,
            refundInfo,
            totalValue,
            systemSizes,
            freebetId,
            totals,
            createdAt,
            insurance,
        ]
    );

    return (
        <BetItemContext.Provider value={value}>
            {children}
        </BetItemContext.Provider>
    );
};

export const useBetItemData = (): IBetItemContext => useContext(BetItemContext);

export default BetItemProvider;
