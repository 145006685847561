import { filter, find } from 'lodash';

import {
    LIVE_SPORTEVENT_STATUSES,
    SCORE_TYPES_TITLE_MAPPING,
    ScoreTypes,
} from 'app-constants';
import { Sports } from 'components/betting/Categorizer/constants';
import FeatureFlagsManager, { Feature } from 'services/features';
import {
    Bet,
    BetStatus,
    BetType,
    CashOutOrderStatusCode,
    CompetitorHomeAway,
    MarketBase,
    MatchBase,
    SportEventStatus,
} from 'types/gql.bet';
import { getByKey } from 'utils';
import {
    SCORE_TYPES_WITHOUT_CHECK_ON_SPEC_VALUE,
    ScoreTypesNumerable,
    SPECIFIER_SCORE_TYPES_MAPPING,
    SPECIFIER_VALUE_SCORE_TYPES_MAPPING,
} from './BetItem/Odd/constants';
import type { ExtendedScoreInfo, Score, Specifier } from './BetItem/Odd/types';
import { getMainSpecifiersBySportId } from './BetItem/Odd/utils';
import { RefundStatus } from './constants';
import type { MarketWithSpecifier, Refund } from './types';

interface GetRefundInfoInput {
    bet: Bet;
    totalWin: number;
}

interface GetScoreInput {
    sportEvent: MatchBase;
    market: MarketBase;
    scores: ExtendedScoreInfo[];
}

export function getScoreType(
    sportId: string,
    { name, value }: Specifier
): ScoreTypes | undefined {
    switch (sportId) {
        case Sports.FOOTBALL:
        case Sports.ESPORTS_FIFA:
        case Sports.HANDBALL: {
            return getByKey(SPECIFIER_VALUE_SCORE_TYPES_MAPPING, value);
        }

        default: {
            return getByKey(SPECIFIER_SCORE_TYPES_MAPPING, name);
        }
    }
}

export function getScore({
    sportEvent,
    market,
    scores,
}: GetScoreInput): Score | null {
    if (!sportEvent || !market) return null;

    const { sportId, score: totalScore, status } = sportEvent.fixture;
    const DEFAULT_RESULT: Score = {
        title: SCORE_TYPES_TITLE_MAPPING.total,
        value: totalScore,
    };

    if (
        !LIVE_SPORTEVENT_STATUSES.includes(status) &&
        status !== SportEventStatus.Ended
    ) {
        return DEFAULT_RESULT;
    }

    const { specifiers } = market as MarketWithSpecifier;

    const mainSpecifier = findMainSpecifierBySportId(specifiers, sportId);

    if (!mainSpecifier) return DEFAULT_RESULT;

    const scoreType = getScoreType(sportId, mainSpecifier);
    const { value } = mainSpecifier;

    if (!scoreType) {
        return DEFAULT_RESULT;
    }

    const specifierValue = 2;

    const currScores = scores.filter(
        ({ type, number }) =>
            scoreType === type &&
            (SCORE_TYPES_WITHOUT_CHECK_ON_SPEC_VALUE.includes(type) ||
                number === specifierValue)
    );

    if (currScores.length !== 2) return DEFAULT_RESULT;

    const scoreValue = currScores.reduce((acc, { homeAway, points }) => {
        if (homeAway === CompetitorHomeAway.Home) return points + acc;

        return acc + points;
    }, ':');

    let title = SCORE_TYPES_TITLE_MAPPING[scoreType];

    if (ScoreTypesNumerable.includes(scoreType)) {
        title += value;
    }

    return {
        title,
        value: scoreValue,
    };
}

export function findMainSpecifierBySportId(
    allSpecifiers: Specifier[],
    sportId: string
): Specifier | undefined {
    const mainSpecifierNames = getMainSpecifiersBySportId(sportId);

    const specifier = filter(allSpecifiers, (current) =>
        mainSpecifierNames.includes(current.name)
    );

    if (specifier.length > 1) {
        console.warn('Multiple main specifiers not allowed');
    }

    return specifier ? specifier[0] : undefined;
}

export function getRefundInfo({ bet, totalWin }: GetRefundInfoInput): Refund {
    const {
        refundSum,
        status,
        stake,
        type,
        freebetId,
        cashOutOrders,
        insurance,
    } = bet;

    const acceptedcashOutOrder = find(
        cashOutOrders,
        ({ status: { code } }) => code === CashOutOrderStatusCode.Accepted
    );

    const isEnableFlagInsurance = FeatureFlagsManager.init().isEnabled(
        Feature.BetslipInsurance
    );

    if (acceptedcashOutOrder) {
        return {
            refundTitle: 'cashout',
            refund: `+${acceptedcashOutOrder.refundAmount}`,
            refundStatus: RefundStatus.CashOuted,
        };
    }

    switch (status) {
        case BetStatus.RolledBack: {
            return {
                refundTitle: 'rollback',
                refund: refundSum,
                refundStatus: RefundStatus.RollBack,
            };
        }

        case BetStatus.Settled: {
            const isWin =
                type === BetType.System || freebetId
                    ? +refundSum > 0
                    : +refundSum > +stake;

            if (isWin) {
                return {
                    refund: `+${refundSum}`,
                    refundTitle: 'payout',
                    refundStatus: RefundStatus.Win,
                };
            }

            if (isEnableFlagInsurance) {
                const refundAmountInsurance = insurance
                    ? (+(insurance?.refundAmount || 0)).toFixed(2)
                    : 0;

                if (refundAmountInsurance) {
                    return {
                        refund: `${refundAmountInsurance}`,
                        refundTitle: 'saved',
                        refundStatus: RefundStatus.Loss,
                    };
                }
            }

            return {
                refund: freebetId ? '' : `-${+stake - +refundSum}`,
                refundTitle: 'payout',
                refundStatus: RefundStatus.Loss,
            };
        }

        default: {
            return {
                refundTitle: 'possiblePayout',
                refund: `+${totalWin}` as const,
                refundStatus: RefundStatus.Pending,
            };
        }
    }
}
