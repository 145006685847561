import type { FC } from 'react';
import cn from 'classnames';

interface Props {
    marketName: string;
    isRefundStatusLoss: boolean;
}

const OddMarket: FC<Props> = ({ marketName, isRefundStatusLoss }) => (
    <div
        className={cn(
            'ml-8 mt-1 line-clamp-2 font-normal',
            isRefundStatusLoss ? 'text-grey-700' : 'text-grey-500'
        )}
    >
        {marketName}
    </div>
);

export default OddMarket;
