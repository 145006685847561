import type { FC } from 'react';
import { memo } from 'react';
import cn from 'classnames';

import { Bet, BetType } from 'types/gql.bet';
import { RefundStatus } from '../../constants';
import { Refund } from '../../types';
import { useBetItemData } from '../BetItemProvider';
import OddDetails from './OddDetails';
import OddMarket from './OddMarket';
import Outcomes from './Outcomes';
import SportEventInfo from './SportEventInfo';
import useOdd from './useOdd';

const OddValidateProps: FC = () => {
    const { odds, refundInfo, betType, totalValue, betId } = useBetItemData();

    if (!odds.length) return null;

    return (
        <Odd
            odds={odds}
            refundInfo={refundInfo}
            betType={betType}
            totalValue={totalValue}
            betId={betId}
        />
    );
};

const Odd: FC<{
    odds: Bet['odds'];
    refundInfo: Refund;
    betType: BetType;
    totalValue: string | number;
    betId: string;
}> = ({ odds, refundInfo, betType, totalValue, betId }) => {
    const {
        outcomesCount,
        sportEvent,
        value,
        sportEventState,
        score,
        betTypeTabs,
        market,
        name,
    } = useOdd({ odds });

    const isOneOdd = outcomesCount === 1;

    const { refundStatus } = refundInfo;
    const isRefundStatusLoss = refundStatus === RefundStatus.Loss;

    return (
        <div className="border-y-default border-surface-dark">
            <div className={cn('flex flex-col typo-sm-2', { 'p-4': isOneOdd })}>
                {isOneOdd ? (
                    <OddDetails
                        name={name}
                        value={value}
                        sportId={sportEvent.fixture.sportId}
                        status={refundStatus}
                        betTypeTabs={betTypeTabs}
                    />
                ) : (
                    <Outcomes
                        odds={odds}
                        totalValue={totalValue}
                        refundInfo={refundInfo}
                        betType={betType}
                        betTypeTabs={betTypeTabs}
                        betId={betId}
                    />
                )}
                {isOneOdd && (
                    <OddMarket
                        marketName={market.name}
                        isRefundStatusLoss={isRefundStatusLoss}
                    />
                )}
            </div>
            <SportEventInfo
                isRefundStatusLoss={isRefundStatusLoss}
                sportEvent={sportEvent}
                sportEventState={sportEventState}
                score={score}
                outcomesCount={outcomesCount}
                betTypeTabs={betTypeTabs}
            />
        </div>
    );
};

export default memo(OddValidateProps);
