import type { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { isBettingGlobalRefundTitle } from 'translations/utils';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { Feature, useFeatureFlags } from 'services/features';
import ConditionalIcon from '../../../ConditionalIcon';
import { RefundStatus } from '../../../constants';
import { useBetItemData } from '../../BetItemProvider';
import useFooter from './useFooter';

const Footer: FC = () => {
    const { refundInfo, totalValue, totals, freebetId, insurance } =
        useBetItemData();
    const { currency, typeName } = useFooter();
    const { refundTitle, refund, refundStatus } = refundInfo;
    const { features } = useFeatureFlags();
    const isInsurance =
        !!insurance && features.isEnabled(Feature.BetslipInsurance);

    const isRefundStatusLoss = refundStatus === RefundStatus.Loss;
    const textColor =
        isRefundStatusLoss && !isInsurance ? 'text-grey-700' : 'text-grey-500';
    const itemClassName = 'flex items-center justify-between px-4';

    const tBettingSpecifiers = useTranslations('betting-specifiers.specifiers');
    const tBettingGlobal = useTranslations('betting-global.global');
    const tBettingBetshistory = useTranslations(
        'betting-betshistory.betsHistory.BetFooter'
    );

    return (
        <div
            className={cn(
                'rounded-default bg-surface-middle',
                isRefundStatusLoss ? 'text-grey-700' : 'text-primary-white'
            )}
        >
            <div className="flex items-center justify-between border-b-default border-surface-dark  p-4 typo-sm-2">
                <div className=" flex items-center justify-between">
                    <ConditionalIcon condition={refundInfo.refundStatus} />
                    <span className="ml-2">{tBettingSpecifiers('total')}</span>
                </div>
                <div className="rounded-default bg-surface-light px-2 py-1">
                    {totalValue}
                </div>
            </div>
            <div className={cn('py-3 typo-sm-1', textColor)}>
                <div className={itemClassName}>
                    <div className="mb-2 flex items-center">
                        {typeName}
                        {freebetId && (
                            <PackIcon
                                id="promo-freebets"
                                pack={IconsPack.SpriteIcons}
                                className={cn(
                                    'ml-1',
                                    isRefundStatusLoss
                                        ? 'fill-grey-700'
                                        : 'fill-turquoise-500'
                                )}
                            />
                        )}
                        {isInsurance && (
                            <PackIcon
                                id="insurance"
                                pack={IconsPack.SpriteIcons}
                                className={cn(
                                    'ml-1',
                                    refundStatus === RefundStatus.Win
                                        ? 'fill-grey-700'
                                        : 'fill-green-700'
                                )}
                            />
                        )}
                    </div>
                    <div
                        className={cn('uppercase', {
                            'text-turquoise-500': freebetId,
                            '!text-grey-700': isRefundStatusLoss,
                        })}
                    >
                        {totals.totalStake}
                        <span className="ml-1">{currency}</span>
                    </div>
                </div>

                <div className={itemClassName}>
                    <div className={cn('capitalize', textColor)}>
                        {isBettingGlobalRefundTitle(refundTitle)
                            ? tBettingGlobal(refundTitle)
                            : tBettingBetshistory(refundTitle)}
                    </div>
                    <div
                        className={cn(
                            'uppercase',
                            isRefundStatusLoss && !isInsurance
                                ? 'text-grey-700'
                                : 'text-green-700'
                        )}
                    >
                        {`${
                            isRefundStatusLoss && !isInsurance ? 0 : refund
                        } ${currency}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
