import type { FC } from 'react';
import { memo } from 'react';
import cn from 'classnames';

import { BetOdd, OddStatus } from 'types/gql.bet';
import { RefundStatus } from '../../../constants';
import OddDetails from '../../Odd/OddDetails';
import OddMarket from '../../Odd/OddMarket';
import SportEventInfo from '../../Odd/SportEventInfo';
import useOdd from '../../Odd/useOdd';

interface Props {
    odds: Array<BetOdd>;
    refundStatus: RefundStatus;
}

const OutcomesOdd: FC<Props> = ({ odds, refundStatus }) => {
    const {
        outcomesCount,
        sportEvent,
        value,
        sportEventState,
        score,
        betTypeTabs,
        market,
        name,
        status,
    } = useOdd({ odds });

    const isRefundStatusLoss =
        refundStatus === RefundStatus.Loss ||
        status === OddStatus.Loss ||
        status === OddStatus.HalfLoss;

    if (!odds.length) return null;

    return (
        <div
            className={cn(
                'mt-0 rounded-default bg-surface-middle typo-xs-1',
                isRefundStatusLoss ? 'text-grey-700' : 'text-primary-white'
            )}
        >
            <div className="flex flex-col p-4 typo-sm-2">
                <OddDetails
                    name={name}
                    oddStatus={status}
                    value={value}
                    sportId={sportEvent.fixture.sportId}
                    status={refundStatus}
                    betTypeTabs={betTypeTabs}
                />
                <OddMarket
                    marketName={market.name}
                    isRefundStatusLoss={isRefundStatusLoss}
                />
            </div>
            <SportEventInfo
                isRefundStatusLoss={isRefundStatusLoss}
                sportEvent={sportEvent}
                sportEventState={sportEventState}
                score={score}
                outcomesCount={outcomesCount}
                betTypeTabs={betTypeTabs}
            />
        </div>
    );
};

export default memo(OutcomesOdd);
