import type { FC } from 'react';

import SportIcon from 'components/SportIcon';
import { OddStatus } from 'types/gql.bet';
import ConditionalIcon from '../../../ConditionalIcon';
import { BetTypeTabs, RefundStatus } from '../../../constants';

interface InputRenderIcon {
    oddStatus?: OddStatus;
    status: RefundStatus;
    betTypeTabs: BetTypeTabs;
    sportId: string;
}

interface Props {
    name: string;
    sportId: string;
    value: number | string;
    betTypeTabs: BetTypeTabs;
    status: RefundStatus;
    oddStatus?: OddStatus;
}

const OddDetails: FC<Props> = ({
    name,
    sportId,
    value,
    betTypeTabs,
    status,
    oddStatus,
}) => (
    <div className="flex items-center justify-between">
        <div className="flex items-center overflow-hidden">
            <RenderIcon
                oddStatus={oddStatus}
                status={status}
                sportId={sportId}
                betTypeTabs={betTypeTabs}
            />

            <span className="mx-2 line-clamp-2">{name}</span>
        </div>
        <div className="flex items-center justify-center ">
            <div className="rounded-default bg-surface-light px-2 py-1">
                {value}
            </div>
        </div>
    </div>
);

const RenderIcon: FC<InputRenderIcon> = ({
    oddStatus,
    status,
    betTypeTabs,
    sportId,
}) => {
    if (oddStatus && oddStatus !== OddStatus.NotResulted) {
        return <ConditionalIcon condition={oddStatus} />;
    }

    if (
        betTypeTabs === BetTypeTabs.Settled &&
        status !== RefundStatus.Pending
    ) {
        return <ConditionalIcon condition={status} />;
    }

    return <SportIcon sportId={sportId} className="flex-none" />;
};

export default OddDetails;
