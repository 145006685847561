import type { FC } from 'react';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { Bet, BetType } from 'types/gql.bet';
import ConditionalIcon from '../../../ConditionalIcon';
import { BetTypeTabs, RefundStatus } from '../../../constants';
import { Refund } from '../../../types';
import OutcomesPortal from '../../OutcomesPortal';
import useOutcomes from './useOutcomes';

interface InputRenderIcon {
    status: RefundStatus;
    betType: BetType;
    betTypeTabs: BetTypeTabs;
}
interface Props {
    betTypeTabs: BetTypeTabs;
    odds: Bet['odds'];
    refundInfo: Refund;
    totalValue: string | number;
    betType: BetType;
    betId: string;
}

const Outcomes: FC<Props> = ({
    betTypeTabs,
    betType,
    refundInfo,
    totalValue,
    odds,
    betId,
}) => {
    const t = useTranslations('betting-global.global');

    const { isOpen, toogle, portal } = useOutcomes();
    const outcomesCount = odds.length;
    const { refundStatus } = refundInfo;
    const isRefundStatusLoss = refundStatus === RefundStatus.Loss;

    return (
        <>
            <div
                className="flex cursor-pointer items-center justify-between p-4"
                onClick={toogle}
            >
                <div className="flex items-center">
                    <RenderIcon
                        betTypeTabs={betTypeTabs}
                        betType={betType}
                        status={refundStatus}
                    />
                    <span className="mx-2 line-clamp-2">
                        {`${outcomesCount} ${t('outcomes', {
                            count: 2,
                        })}`}
                    </span>
                </div>
                <div className="flex items-center justify-center ">
                    <div className="rounded-default bg-surface-light px-2 py-1">
                        {totalValue}
                    </div>

                    <div className="ml-2 rounded-default bg-surface-light">
                        <PackIcon
                            id="branch-chevron-left"
                            pack={IconsPack.SpriteIcons}
                            className={cn(
                                'rotate-180',
                                isRefundStatusLoss
                                    ? 'fill-grey-700'
                                    : 'fill-grey-500'
                            )}
                        />
                    </div>
                </div>
            </div>
            {isOpen &&
                portal &&
                createPortal(
                    <OutcomesPortal
                        betId={betId}
                        betType={betType}
                        toogle={toogle}
                        odds={odds}
                        refundStatus={refundStatus}
                    />,
                    portal
                )}
        </>
    );
};

const RenderIcon: FC<InputRenderIcon> = ({ status, betType, betTypeTabs }) => {
    if (betTypeTabs === BetTypeTabs.Settled) {
        return <ConditionalIcon condition={status} />;
    }

    return <ConditionalIcon condition={betType} />;
};

export default memo(Outcomes);
