import { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

interface Output {
    isCopy: boolean;
    onCopy: VoidFunction;
}

const COPIED_DURATION = 2000 as const;

const useBetHeader = (copyData: string): Output => {
    const [isCopy, setIsCopy] = useState<boolean>(false);

    useEffect(() => {
        if (!isCopy) return;

        const timer = window.setTimeout(() => {
            setIsCopy(false);
        }, COPIED_DURATION);

        return () => clearTimeout(timer);
    }, [isCopy]);

    const onCopy = useCallback(() => {
        copy(copyData);

        setIsCopy(true);
    }, [copyData]);

    return {
        isCopy,
        onCopy,
    };
};

export default useBetHeader;
