import type { FC } from 'react';
import { memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack } from 'components/PackIcon';
import useBufferCopy from './useBufferCopy';

interface Props {
    isShowCopyText?: boolean;
    text?: string;
    copyData: string;
    copyIconCn?: string;
    checkIconCn?: string;
}

const BufferCopy: FC<Props> = ({
    copyData,
    copyIconCn,
    checkIconCn,
    text,
    isShowCopyText = true,
}) => {
    const { isCopy, onCopy } = useBufferCopy(copyData);

    const t = useTranslations('betting-betshistory.betsHistory.BetsHistory');

    return (
        <div
            className={cn('flex cursor-copy items-center justify-between')}
            onClick={onCopy}
        >
            <div className={cn('mr-2', { 'typo-sm-1 text-green-700': isCopy })}>
                {isCopy ? isShowCopyText && t('copied') : text}
            </div>
            {isCopy ? (
                <PackIcon
                    id="chevron"
                    pack={IconsPack.SpriteIcons}
                    className={checkIconCn}
                />
            ) : (
                <PackIcon
                    id="copy-content"
                    pack={IconsPack.SpriteIcons}
                    className={copyIconCn}
                />
            )}
        </div>
    );
};

export default memo(BufferCopy);
