import type { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import BufferCopy from 'components/betting/BufferCopy';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { BetType } from 'types/gql.bet';

interface Props {
    onClickHandler: VoidFunction;
    betId: string;
    betType: BetType;
}

const Header: FC<Props> = ({ onClickHandler, betType, betId }) => {
    const t = useTranslations('betting-global.global');

    return (
        <div className="flex h-12 items-center justify-between px-3 pb-2">
            <PackIcon
                id="left"
                pack={IconsPack.SpriteIcons}
                className={cn('cursor-pointer fill-primary-orange-toxic')}
                onClick={onClickHandler}
            />
            <div className="font-bold uppercase text-primary-orange-toxic typo-base">
                <span>{betType && t(betType)}</span>
                <span className="ml-1">{`#...${betId.slice(-10)}`}</span>
            </div>
            <BufferCopy
                isShowCopyText={false}
                copyData={betId}
                copyIconCn={cn('fill-primary-orange-toxic')}
                checkIconCn={cn('fill-primary-orange-toxic')}
            />
        </div>
    );
};

export default Header;
