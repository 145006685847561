import { useTranslations } from 'next-intl';

import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';
import { BetType } from 'types/gql.bet';
import { useBetItemData } from '../../BetItemProvider';

interface Output {
    currency: string | undefined;
    typeName: string;
}

function useFooter(): Output {
    const { odds, betType, systemSizes } = useBetItemData();

    const t = useTranslations('betting-global.global');

    const oddsSize = odds.length;

    const typeName = `${t(betType)} ${
        betType === BetType.System
            ? systemSizes.join(`/${oddsSize}, `).concat(`/${oddsSize}`)
            : ''
    }`;

    const { data: dataCurrency } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));

    return {
        currency: dataCurrency?.currency,
        typeName,
    };
}

export default useFooter;
