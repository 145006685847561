import { useReactiveVar } from '@apollo/client';
import { floor, map, transform } from 'lodash';

import { getTotals } from 'components/betting/Betslip/utils';
import { gamblerSettingsVar } from 'components/betting/GamblerSettings/gamblerState';
import type { Bet } from 'types/gql.bet';
import { formatDecimalOdd } from 'utils';
import { Totals } from '../types';

interface Input {
    bet: Pick<Bet, 'type' | 'odds' | 'stake' | 'freebetId' | 'systemSizes'>;
}

interface Output {
    totals: Totals;
    totalValue: string | number;
}

const useBetItem = ({
    bet: { type, stake, freebetId, systemSizes, odds },
}: Input): Output => {
    const totals = getTotals({
        betType: type,
        oddValues: map(odds, ({ odd }) => +odd.value),
        stake,
        isFreebet: Boolean(freebetId),
        systemSize: systemSizes[0],
    });

    const { oddFormat } = useReactiveVar(gamblerSettingsVar);

    const newTotals = transform(totals, (acc: Totals, value, key) => {
        acc[key] = floor(value || 0, 2);
    });

    return {
        totals: newTotals,
        totalValue: formatDecimalOdd(newTotals.totalOdd || 0, oddFormat),
    };
};

export default useBetItem;
