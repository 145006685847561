import { useCallback, useState } from 'react';

import { isSsr } from 'utils/isSsr';

interface Output {
    isOpen: boolean;
    toogle: VoidFunction;
    portal: HTMLElement | null;
}

const useOutcomes = (): Output => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const portal = !isSsr()
        ? document.getElementById('bet-outcomes-portal')
        : null;

    const toogle = useCallback(() => setIsOpen((isOPen) => !isOPen), []);

    return {
        isOpen,
        toogle,
        portal,
    };
};

export default useOutcomes;
